import styled from "styled-components";

const Tag = styled.span`
  background-color: ${({ primary, theme }) =>
    primary ? theme.palette.text.light : "rgba(255, 255, 255, 0.05)"};

  color: ${({ primary, theme }) =>
    primary ? theme.palette.text.dark : theme.palette.text.mid};
  border-radius: 4px;
  display: inline-flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  padding: 6px 8px;
`;

export const TagGroup = styled.div`
  flex: none;
  display: block;
  flex-wrap: wrap;
  margin: 16px -4px -4px -4px;
  width: 100%;

  ${Tag} {
    margin: 4px;
  }
`;

export default Tag;
