import React from "react";

import Button from "../../components/button";
import Icon from "../../components/icon";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

import pageTransitionScreenshot from "../../images/page-transition.gif";

function PageTransitionSection() {
  return (
    <SplitHero reverse>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            React Page Transition
          </Text>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          A React component that makes it easy to use page transitions.
          <TagGroup>
            <Tag>Babel</Tag>
            <Tag>CSS Animation</Tag>
            <Tag>CSS Transforms</Tag>
            <Tag>CSS</Tag>
            <Tag>Javascript</Tag>
            <Tag>npm</Tag>
            <Tag>React</Tag>
            <Tag>Styled Components</Tag>
            <Tag>Webpack</Tag>
          </TagGroup>
        </Text>

        <Button
          href="https://github.com/Steveeeie/react-page-transition"
          as="a"
          target="__blank"
          rel="nofollow"
          marginTop={4}
          small
        >
          View On GitHub <Icon icon="github" marginLeft={0} />
        </Button>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <img src={pageTransitionScreenshot} alt="React Page Transition" />
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default PageTransitionSection;
